import { cloneDeep } from 'lodash';

function compareKeys(a, b) {
  for (const key of Object.keys(a)) {
    if (a[key] !== b[key]) {
      return true;
    }
  }
  return false;
}

export async function updateSkills(holderEntityId, holderEntityService, oldSkills, newSkills) {
  const result = {
    hasError: false,
    errorCodes: []
  }
  const skills = cloneDeep(newSkills);
  const originSkills = oldSkills;
  const toAdd = [];
  const toUpdate = [];
  const unchangedIds = [];
  for (let i = 0, len = skills.length; i < len; i++) {
    const skill = skills[i];
    const index = originSkills.findIndex(j => j.uuId === skill.uuId);
    if (index == -1) {
      toAdd.push(skill);
    } else {
      const originSkill = originSkills[index];
      if (compareKeys(skill, originSkill)) {
        toUpdate.push(skill);
      } else {
        unchangedIds.push(skill.uuId);
      }
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toUpdateIds = toUpdate.map(i => i.uuId);
  const toRemove = originSkills.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));

  //To add new skills if there is any.
  if (toAdd.length > 0) {
    let { needUpdate=[], hasError=false, errorCode=null } = await holderEntityService.create(holderEntityId, toAdd)
    .then(response => {
      if (207 == response.status) {
        const list = response.data[response.data.jobCase];
        const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
        const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
        const failSkills = toAdd.filter(i => failIds.includes(i.uuId));
        return { 
          needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), 
          hasError: failSkills.length > 0,
          errorCode: 207
        };
      }
      return {}
    })
    .catch(e => {
      if (e.response && 422 == e.response.status) {
        const list = e.response.data[e.response.data.jobCase];
        const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
        const failedList = toAdd.filter(i => !toUpdateIds.includes(i.uuId));
        return { 
          needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId))
          , hasError: failedList.length > 0
          , errorCode: 422
        };
      } else {
        return { hasError: true, errorCode: e != null && e.response != null ? e.response.status : null }
      }
    });
    
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }

    if (needUpdate != null && needUpdate.length > 0) {
      //Collect those skills require Update (instead of add) and add them to toUpdate list.
      toUpdate.splice(toUpdate.length, 0, ...needUpdate);  
    }
  }

  //To update existing skills if there is any.
  if (toUpdate.length > 0) {
    let { needAdd=[], hasError=false, errorCode=null } = await holderEntityService.update(holderEntityId, toUpdate)
      .then(response => {
        if (207 == response.status) {
          const list = response.data[response.data.jobCase];
          const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
          const failIds = list.filter(i => i.clue !== 'Unknown_relation' && i.clue !== 'OK').map(i => i.args[0]);
          const failSkills = toUpdate.filter(i => failIds.includes(i.uuId));
          return { 
            needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId))
            , hasError: failSkills.length > 0
            , errorCode: 207
          };
        }
        return {}
      })
      .catch(e => {
        if (e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
          const failedList = toUpdate.filter(i => !toAddIds.includes(i.uuId));
          return { 
            needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), 
            hasError: failedList.length > 0,
            errorCode: 422
          };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null ? e.response.status : null }
        }
      });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }

    //To add skills which require Add operation (instead of Update) if there is any.
    if (needAdd.length > 0) {
      let { hasError=false, errorCode=null } = await holderEntityService.create(holderEntityId, needAdd)
      .then(response => {
        if (207 == response.status) {
          const list = response.data[response.data.jobCase];
          const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 207 };
        }
        return {}
      })
      .catch(e => {
        if (e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
          return { 
            needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), 
            failed: toAdd.filter(i => !toUpdateIds.includes(i.uuId)),
            errorCode: 422
          };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null ? e.response.status : null }
        }
      });
      if (hasError) {
        result.hasError = true;
        result.errorCodes.push(errorCode);
        return result;
      }
    }
  }

  //Try remove skills which user wants to remove if there is any
  if (toRemove.length > 0) {
    const clues = ['OK', 'Unknown_relation'];
    let { hasError=false, errorCode=null } = await holderEntityService.remove(holderEntityId, toRemove)
    .then(response => {
      if (207 == response.status) {
        const list = response.data[response.data.jobCase];
        const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
        return { hasError: failIds.length > 0, errorCode: 207 };
      }
      return {};
    })
    .catch(e => {
      if (e.response && 422 == e.response.status) {
        const list = e.response.data[e.response.data.jobCase];
        const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
        return { hasError: failIds.length > 0, errorCode: 422 };
      } else {
        return { hasError: true, errorCode: e != null && e.response != null ? e.response.status : null }
      }
    });

    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }

  return result;
}